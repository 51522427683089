<template>
  <div class="brand-goods">
    <div id="breadcrumb">
      <div class="breadcrumb-con">
        <p>您的位置：</p>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item to="/Home">首页</el-breadcrumb-item>
          <el-breadcrumb-item to="/Brand/AllBrand">全部品牌</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $route.query.brandName }}</el-breadcrumb-item>
        </el-breadcrumb>
        <svg-icon
          icon-class="ic-close"
          @click="$router.push('/Brand/AllBrand')"
        />
      </div>
    </div>
    <goods-list />
  </div>
</template>

<script>
import GoodsList from "@/components/GoodsList";

export default {
  name: "brand-goods",
  components: { GoodsList },
  data() {
    return {};
  },
  created() {
    console.log(this.$route);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.brand-goods {
  background: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 1px var(--subjectColor);
  #breadcrumb {
    background: #fff;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    .breadcrumb-con {
      width: 1200px;
      height: 55px;
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        color: #999;
      }
      ::v-deep.el-breadcrumb {
        span {
          color: #999;
        }
        .el-breadcrumb__inner {
          font-weight: normal;
        }
        .el-breadcrumb__inner:hover {
          color: var(--subjectColor);
        }
      }
      ::v-deep.el-breadcrumb .el-breadcrumb__item:last-child span {
        color: #333;
        cursor: default;
      }
      .svg-icon {
        margin-left: 4px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
      .svg-icon:hover {
        color: #000;
      }
    }
  }
}
</style>